import React from "react";
import "./numberRound.css";
import { Link } from "react-router-dom";
import { Timer } from "./timer.js";
import { Header } from "./headerAndBackground.js";
import themeTimer from "./Countdown - Clock Only.mp3";

let _ = require("lodash");

const numberArrayMaker = (number, frequency) =>
  _.fill(Array(frequency), number);

const targetNumberMaker = Math.floor(Math.random() * (999 - 100) + 100);

let initialState = {
  numbers: [],
  smallNumbers: _.shuffle(
    _.concat(
      numberArrayMaker(1, 2),
      numberArrayMaker(2, 2),
      numberArrayMaker(3, 2),
      numberArrayMaker(4, 2),
      numberArrayMaker(5, 2),
      numberArrayMaker(6, 2),
      numberArrayMaker(7, 2),
      numberArrayMaker(8, 2),
      numberArrayMaker(9, 2),
      numberArrayMaker(10, 2)
    )
  ),
  largeNumbers: _.shuffle([25, 50, 75, 100]),
  targetNumber: [],
};

let onUserAction = (state, action) => {
  if (action === "small number clicked" && state.numbers.length <= 5) {
    let [firstSmallNumber, ...remainingSmallNumbers] = state.smallNumbers;
    return {
      ...state,
      smallNumbers: remainingSmallNumbers,
      numbers: [...state.numbers, firstSmallNumber],
    };
  }
  if (
    action === "large number clicked" &&
    state.numbers.length <= 5 &&
    state.largeNumbers.length > 0
  ) {
    let [firstLargeNumber, ...remainingLargeNumbers] = state.largeNumbers;
    return {
      ...state,
      largeNumbers: remainingLargeNumbers,
      numbers: [...state.numbers, firstLargeNumber],
    };
  }
  if (action === "large number clicked" && state.largeNumbers.length === 0) {
    alert("You may only select 4 large numbers");
    return {
      ...state,
    };
  }
  if (action === "small number clicked" && state.numbers.length === 6) {
    alert("You have already selected six numbers");
    return {
      ...state,
    };
  }
  if (action === "large number clicked" && state.numbers.length === 6) {
    alert("You have already selected six numbers");
    return {
      ...state,
    };
  }
  if (action === "target number clicked" && state.numbers.length === 6) {
    return {
      ...state,
      targetNumber: targetNumberMaker,
    };
  }
  if (action === "target number clicked" && state.numbers.length < 6) {
    alert("You must choose six numbers first");
    return {
      ...state,
    };
  }
  throw new Error("How has this happened!?" + action);
};

let initialTimerState = {
  time: 30,
  angle: 0,
};

let onUserActionTimer = (timerState, action) => {
  if (action === "timer started") {
    let newTime = timerState.time - 1;
    return {
      ...timerState,
      time: newTime,
    };
  }
  throw new Error("Unrecognised action: " + action);
};

function Numbers() {
  let [state, runAction] = React.useReducer(onUserAction, initialState);

  let array6 = _.fill(Array(6 - state.numbers.length), "");

  let target = state.targetNumber;

  const onSmallClick = () => {
    runAction("small number clicked");
  };

  const onLargeClick = () => {
    runAction("large number clicked");
  };

  const onTargetClick = () => {
    runAction("target number clicked");
    if (state.numbers.length === 6) {
      startTimer();
    }
  };

  let [timerState, runTimerAction] = React.useReducer(
    onUserActionTimer,
    initialTimerState
  );

  const startTimer = () => {
    let runOnceASecond = () => {
      runTimerAction("timer started");
    };
    let timerId = setInterval(runOnceASecond, 1000);
    setTimeout(() => {
      clearInterval(timerId);
    }, 30000);
    let audioStart = audioRef.current.play();
    return audioStart;
  };

  let audioRef = React.useRef();

  return (
    <div className="background">
      <Header />
      <div className="pageBody">
        <Timer timeRemaining={timerState.time} />
        <div className="audio">
          <audio ref={audioRef} src={themeTimer} />
        </div>

        <div className="numberTiles">
          {state.numbers.map((number, index) => (
            <div className="tiles" key={index}>
              {number}
            </div>
          ))}
          {array6.map((number, index) => (
            <div className="tiles" key={index}>
              {number}
            </div>
          ))}
        </div>

        <div className="numberRoundButtonRow">
          <button className="numberRoundButtons" onClick={onSmallClick}>
            {" "}
            Small{" "}
          </button>
          <button className="numberRoundButtons" onClick={onLargeClick}>
            {" "}
            Large{" "}
          </button>
          <button className="numberRoundButtons" onClick={onTargetClick}>
            Target
          </button>
          <Link to="./" className="numberRoundLink">
            Home
          </Link>
        </div>

        <div className="targetSection">
          <button className="tiles">{target}</button>
        </div>
      </div>
    </div>
  );
}

export default Numbers;
