import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import LetterRound from "./letterRound.js";
import NumberRound from "./numberRound.js";
import Conundrums from "./conundrums.js";
import "./App.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/letterRound">
          <LetterRound />
        </Route>
        <Route path="/numberRound">
          <NumberRound />
        </Route>
        <Route path="/conundrums">
          <Conundrums />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </Router>
  );
}

function HomePage() {
  return (
    <div className="background">
      <div className="header">
        <div className="titleLetters">C</div>
        <div className="titleLetters">O</div>
        <div className="titleLetters">U</div>
        <div className="titleLetters">N</div>
        <div className="titleLetters">T</div>
        <div className="titleLetters">D</div>
        <div className="titleLetters">O</div>
        <div className="titleLetters">W</div>
        <div className="titleLetters">N</div>
      </div>

      <div className="pageBody">
        <div className="buttonColumn">
          <Link to="/letterRound" className="link">
            Letter Round
          </Link>
          <Link to="/numberRound" className="link">
            Number Round
          </Link>
          <Link to="/conundrums" className="link">
            Conundrum
          </Link>
        </div>
      </div>
    </div>
  );
}

export default App;
