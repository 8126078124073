import React from "react";
import "./header.css";

function Header() {
  return (
    <div className="header">
      <div className="titleLetters">C</div>
      <div className="titleLetters">O</div>
      <div className="titleLetters">U</div>
      <div className="titleLetters">N</div>
      <div className="titleLetters">T</div>
      <div className="titleLetters">D</div>
      <div className="titleLetters">O</div>
      <div className="titleLetters">W</div>
      <div className="titleLetters">N</div>
    </div>
  );
}

// function Tiles() {
//   return (

//   )
// }

export { Header };
