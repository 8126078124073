import React from "react";
import "./letterRound.css";
import { Link } from "react-router-dom";
import { Timer } from "./timer.js";
import { Header } from "./headerAndBackground.js";
import themeTimer from "./Countdown - Clock Only.mp3";

let _ = require("lodash");

const letterArrayMaker = (letter, number) => _.fill(Array(number), letter);

let initialState = {
  letters: [],
  vowels: _.shuffle(
    _.concat(
      letterArrayMaker("A", 15),
      letterArrayMaker("E", 21),
      letterArrayMaker("I", 13),
      letterArrayMaker("O", 13),
      letterArrayMaker("U", 5)
    )
  ),
  consonants: _.shuffle(
    _.concat(
      letterArrayMaker("B", 2),
      letterArrayMaker("C", 3),
      letterArrayMaker("D", 6),
      letterArrayMaker("F", 2),
      letterArrayMaker("G", 3),
      letterArrayMaker("H", 2),
      letterArrayMaker("J", 1),
      letterArrayMaker("K", 1),
      letterArrayMaker("L", 5),
      letterArrayMaker("M", 4),
      letterArrayMaker("N", 8),
      letterArrayMaker("P", 4),
      letterArrayMaker("Q", 1),
      letterArrayMaker("R", 9),
      letterArrayMaker("S", 9),
      letterArrayMaker("T", 9),
      letterArrayMaker("V", 1),
      letterArrayMaker("W", 1),
      letterArrayMaker("X", 1),
      letterArrayMaker("Y", 1),
      letterArrayMaker("Z", 1)
    )
  ),
};

let onUserAction = (state, action) => {
  if (action === "vowel selected" && state.letters.length <= 8) {
    let [firstVowel, ...remainingVowels] = state.vowels;
    return {
      ...state,
      vowels: remainingVowels,
      letters: [...state.letters, firstVowel],
    };
  }
  if (action === "consonant selected" && state.letters.length <= 8) {
    let [firstConsonant, ...remainingConsonants] = state.consonants;
    return {
      ...state,
      consonants: remainingConsonants,
      letters: [...state.letters, firstConsonant],
    };
  }
  if (
    action === "vowel selected" ||
    (action === "consonant selected" && state.letters.length === 9)
  ) {
    alert("You have already selected nine letters");
    return {
      ...state,
    };
  }
  if (action === "refresh selected") {
    return {
      ...state,
      letters: [],
    };
  }

  throw new Error("Something has gone wrong. " + action);
};

let initialTimerState = {
  time: 30,
  angle: 0,
};

let onUserActionTimer = (timerState, action) => {
  if (action === "timer started") {
    let newtime = timerState.time - 1;
    return {
      ...timerState,
      time: newtime,
    };
  }
  throw new Error("Unrecognised action: " + action);
};

function Letters() {
  let [state, runAction] = React.useReducer(onUserAction, initialState);

  let array9 = _.fill(Array(9 - state.letters.length), "");

  const onVowelClicked = () => {
    runAction("vowel selected");
    if (state.letters.length === 8) {
      startTimer();
    }
  };

  const onConsonantClicked = () => {
    runAction("consonant selected");
    if (state.letters.length === 8) {
      startTimer();
    }
  };

  const onRefreshClicked = () => {
    runAction("refresh selected");
  };

  let [timerState, runTimerAction] = React.useReducer(
    onUserActionTimer,
    initialTimerState
  );

  const startTimer = () => {
    let runOnceASecond = () => {
      runTimerAction("timer started");
    };
    let timerId = setInterval(runOnceASecond, 1000);
    setTimeout(() => {
      clearInterval(timerId);
    }, 30000);
    let audioStart = audioRef.current.play();
    return audioStart;
  };

  let audioRef = React.useRef();

  return (
    <div className="background">
      <Header />
      <div className="pageBody">
        <Timer timeRemaining={timerState.time} />
        <div className="audio">
          <audio ref={audioRef} src={themeTimer} />
        </div>

        <div className="letterTiles">
          {state.letters.map((letter, index) => (
            <div className="letterRoundTiles" key={index}>
              <div className="letterRoundLetters"> {letter} </div>
            </div>
          ))}
          {array9.map((letter, index) => (
            <div className="letterRoundTiles" key={index}>
              <div className="letterRoundLetters"> {letter} </div>
            </div>
          ))}
        </div>

        <div className="letterRoundButtonRow">
          <button className="letterRoundButtons" onClick={onVowelClicked}>
            {" "}
            Vowel{" "}
          </button>
          <button className="letterRoundButtons" onClick={onConsonantClicked}>
            {" "}
            Consonant{" "}
          </button>
          <button className="letterRoundButtons" onClick={onRefreshClicked}>
            Play again
          </button>
          <Link to="./" className="letterRoundLink">
            Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Letters;
