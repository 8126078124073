import React from "react";
import "./timer.css";

const angleFromTime = (timeRemaining) => {
  let angle = timeRemaining - 30 + 7 * (30 - timeRemaining);
  return angle;
};

function Timer({ timeRemaining }) {
  return (
    <div className="timer">
      <div className="clockFace">
        <div
          className="secondHand"
          style={{ transform: `rotate(${angleFromTime(timeRemaining)}deg)` }}
        />
        <div className="centre"></div>
        <div className="circleOuter" />
      </div>
    </div>
  );
}

export { Timer };
