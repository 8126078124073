import React from "react";
import "./conundrums.css";
import { Link } from "react-router-dom";
import { Timer } from "./timer.js";
import { Header } from "./headerAndBackground.js";
import themeTimer from "./Countdown - Clock Only.mp3";

let _ = require("lodash");
let dictionary = require("./Dictionary.json");

const keys = Object.keys(dictionary);

const nineLetterWords = keys.filter(
  (word) => word.length === 9 && !_.includes(word, "-")
);
const fiveLetterWords = keys.filter((word) => word.length === 5);
const fourLetterWords = keys.filter((word) => word.length === 4);

const turnsCharacterArrayIntObject = (letters) => {
  let lettersToFrequency = {};
  letters.forEach((letter) => {
    if (lettersToFrequency[letter] === undefined) {
      lettersToFrequency[letter] = 1;
    } else {
      lettersToFrequency[letter] = lettersToFrequency[letter] + 1;
    }
  });
  return lettersToFrequency;
};

const subtractsLetters = (lettersToFrequencyObject, wordToBeSubtracted) => {
  let result = _.clone(lettersToFrequencyObject);
  wordToBeSubtracted.forEach((letter) => {
    let letterFrequency = result[letter] || 0;
    result[letter] = letterFrequency - 1;
  });
  return result;
};

const hasAllPositive = (lettersToFrequencyObject) => {
  let arrayOfValues = Object.values(lettersToFrequencyObject);
  return _.every(arrayOfValues, (number) => number >= 0);
};

const checksIfNineLetterWordWorks = () => {
  let solutionFive = null;
  let solutionFour = null;
  let selectedNineWord = _.find(_.shuffle(nineLetterWords), (nineWord) => {
    let characterArray = nineWord.split("");
    let fiveLetterWord = _.find(_.shuffle(fiveLetterWords), (fiveWord) => {
      let subtractedNineLetterObject1 = subtractsLetters(
        turnsCharacterArrayIntObject(characterArray),
        fiveWord.split("")
      );

      let isAllPositive = hasAllPositive(subtractedNineLetterObject1);
      if (isAllPositive) {
        let fourLetterWord = _.find(_.shuffle(fourLetterWords), (fourWord) => {
          let subtractedNineLetterObject2 = subtractsLetters(
            subtractedNineLetterObject1,
            fourWord.split("")
          );
          let isResultAllPositive = hasAllPositive(subtractedNineLetterObject2);
          if (isResultAllPositive) {
            solutionFive = fiveWord;
            solutionFour = fourWord;
            return true;
          } else {
            return false;
          }
        });

        if (fourLetterWord === undefined) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
    if (fiveLetterWord === undefined) {
      return false;
    } else {
      return true;
    }
  });

  return {
    nine: selectedNineWord,
    five: solutionFive,
    four: solutionFour,
  };
};

let initialState = {
  conundrumObject: null,
  letters: ["", "", "", "", "", "", "", "", ""],
  definition: null,
};

let onUserAction = (state, action) => {
  if (action === "conundrum clicked") {
    let conundrumObject = checksIfNineLetterWordWorks();
    let selectedFiveWord = conundrumObject.five;
    let selectedFourWord = conundrumObject.four;

    let mixedUpConundrum = selectedFiveWord + selectedFourWord;
    return {
      ...state,
      conundrumObject: conundrumObject,
      letters: mixedUpConundrum.split(""),
      definition: null,
    };
  }
  if (action === "answer clicked") {
    let originalNineWord = state.conundrumObject.nine;
    return {
      ...state,
      letters: originalNineWord.split(""),
      definition: dictionary[originalNineWord],
    };
  }

  throw new Error("Awwwww hell, something's gone wrong!" + action);
};

let initialTimerState = {
  time: 30,
  angle: 0,
};

let onUserActionTimer = (timerState, action) => {
  if (action === "timer started") {
    let newTime = timerState.time - 1;
    return {
      ...timerState,
      time: newTime,
    };
  }
  throw new Error("Unrecognised action: " + action);
};

function Conundrums() {
  let [state, runAction] = React.useReducer(onUserAction, initialState);

  let letterTiles = state.letters.map((letter, index) => (
    <div className="conundrumTiles" key={index}>
      {letter}
    </div>
  ));

  let definition = state.definition;

  const onConundrumClick = () => {
    runAction("conundrum clicked");
    startTimer();
  };

  const onAnswerClick = () => {
    runAction("answer clicked");
  };

  let [timerState, runTimerAction] = React.useReducer(
    onUserActionTimer,
    initialTimerState
  );

  const startTimer = () => {
    let runOnceASecond = () => {
      runTimerAction("timer started");
    };
    let timerId = setInterval(runOnceASecond, 1000);
    setTimeout(() => {
      clearInterval(timerId);
    }, 30000);
    let audioStart = audioRef.current.play();
    return audioStart;
  };

  let audioRef = React.useRef();

  return (
    <div className="background">
      <Header />
      <div className="pageBody">
        <Timer timeRemaining={timerState.time} />
        <div className="audio">
          <audio ref={audioRef} src={themeTimer} />
        </div>

        <div className="conundrumLetterTiles">{letterTiles}</div>
        <div className="definition">{definition}</div>

        <div className="conundrumButtonSection">
          <button className="conundrumButtons" onClick={onConundrumClick}>
            {" "}
            Conundrum{" "}
          </button>
          <button className="conundrumButtons" onClick={onAnswerClick}>
            Answer
          </button>
          <Link to="/" className="conundrumLink">
            Home
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Conundrums;
